.footer {
    width: 100%;
    height: 20px;
    background-color: #634281;
    color: white;
    text-align: center;
    padding: 20px;
    border-top: 2px solid #f97d33;
}

.footerMenu {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footerMenuItem {
    display: inline;
    padding: 0 10px;
    border-left: solid 2px #f97d33;
    /*font-size: 0.9em; /* Smaller font size */
}

.footerIcon {
    font-size: 20px;
    cursor: pointer;
    padding: 0 5px;
    transition: color 0.3s ease;
}

.firstItem {
    border-left: none;
}

.footerLink {
    color: white;
    text-decoration: none;
}

.footerLink:hover {
    text-decoration: underline;
    color: #f97d33;
}

.footer .footerMenuItem svg {
    vertical-align: middle;
}
