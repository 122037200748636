/* General Styles */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
}

.App-header {
  flex-shrink: 0;
}

.logo-image {
  height: 50px; /* Adjust the height as needed */
  width: auto;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  color: white;
  background-color: #634281;
  border-bottom: 2px solid #f97d33;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 15px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #f97d33;
}

.hero {
  background-color: #2c2c2c;
  padding: 0px;
}

.hero-content {
  color: white;
}

.hero h1 {
  font-size: 2.5rem;
}

.hero p {
  font-size: 1.2rem;
}

.about, .first, .second {
  padding-bottom: 20px;
}

.first {
  background-color: rgb(150, 150, 150);
  color: white;
}

.second {
  background-color: rgb(100, 100, 100);
  color: white;
}

.image-container {
  width: 50%;
  border-radius: 10px; /* Optional: Rounded corners */
  border-width: 10px;
  border: 2px, solid, #b6b6b6ff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Subtle shadow */
}



/* Unified Input and Button Styles */
.cta-buttons {
  display: flex;
  align-items: center;
  gap: 20px; /* Space between waitlist group and Request a Demo button */
  justify-content: center;
  padding-bottom: 40px;
}

.waitlist-input-group {
  display: flex;
  align-items: center;
  gap: 0; /* Remove space between input and button */
}

.waitlist-input {
  padding: 10px;
  font-size: 1rem;
  border: 2px solid #634281; /* Violet border to match button */
  border-radius: 5px; /* Rounded corners */
  width: 250px;
  outline: none;
  transition: border-color 0.3s ease;
  border-right: none; /* Remove border overlap between input and button */
  border-top-right-radius: 0; /* Smooth transition between input and button */
  border-bottom-right-radius: 0;
}

.waitlist-input:focus {
  border-color: #f97d33;
}

.cta-button {
  background-color: #634281;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
  border: none; /* Remove button border */
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 2px solid #634281; /* Violet border to match button */
}

.cta-button:hover {
  background-color: #f97d33;
  border: 2px solid #f97d33; /* Violet border to match button */
}

.waitlist-input-group .cta-button {
  border-top-left-radius: 0; /* Smooth transition between input and button */
  border-bottom-left-radius: 0;
}